/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import { RouteComponentProps } from '@reach/router';
import instance, { ApiResponse } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import {
    completeUIUpdate,
    NotificationIdentifier,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { endpoints } from 'endpoints.config';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FillerImage from 'assets/ibanera/IbaneraBounce.gif';
import { ProductType } from 'components/sideMenu/SideMenu';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { AccountType } from './AccountPage';
import { WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import { useIsSubledgerAccount } from 'helpers/useIsSubledgerAccount';
import { useGetFiatAccountDetails } from 'helpers/useGetFiatAccountDetails';
import { ManualCollapse } from 'components/Collapse/Collapse';
export type Props = {
    accountNumber: string;
    accountType: AccountType;
};

type SavedCard = {
    id: number;
    cardholderName: string;
    cardDescription: string;
};

type FormValues = {
    cardType: 'New card' | 'Saved card' | null;
    customerAssetAccountsId: number;
    amount?: number;
    bSaveCard: boolean;
    paymentCardsId: number;
    cvV2: string;
};

export const PayIn: React.FC<Props & RouteComponentProps> = ({
    accountNumber,
    navigate,
    accountType,
}) => {
    const [iframeUrl, setIframeUrl] = useState('');
    const uiUpdate = useSelector(selectUIUpdate);
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            uiUpdate?.pushType === NotificationIdentifier.CLOSE_DALPAY_IFRAME &&
            uiUpdate.data.guid === ''
        ) {
            setIframeUrl('');
            if (!uiUpdate.data.bVoid && navigate) navigate('../transactions');
            dispatch(completeUIUpdate());
        }
    }, [uiUpdate, dispatch, navigate]);

    return (
        <div className="PayInPage">
            <div className="LeftSide">
                {iframeUrl ? (
                    <div className="IframeContainer">
                        <iframe title="Payment form" src={iframeUrl} height={500}></iframe>
                        <div className="IframePlaceholder">preparing payment form...</div>
                    </div>
                ) : (
                    <>
                        <p>To top up your account, make a bank transfer to the address below.</p>
                        <TransferDetails accountType={accountType} accountNumber={accountNumber} />
                        <SubledgerDisclaimer
                            accountNumber={accountNumber}
                            accountType={accountType}
                        />
                    </>
                )}
            </div>
            <img src={FillerImage} alt="" className="FillerImage" />
        </div>
    );
};

const SubledgerDisclaimer = ({ accountNumber }: Props) => {
    const isSubledger = useIsSubledgerAccount(parseInt(accountNumber));
    return isSubledger ? (
        <div className="SubledgerInfo">
            <h3 className="head">
                <div className="ExclamationIcon">!</div>
                Additional Information
            </h3>
            <p>
                The name on your bank account <u>must</u> match the name on your Ibanera account.
            </p>
            <p>
                Deposits not in USD will be converted to USD and are subject to additional banking
                fees.
            </p>
        </div>
    ) : null;
};

export const SavedCardForm = (props: {
    errorMessage?: string;
    plainForm?: boolean;
    cardListUrl?: string;
    accordion?: boolean;
    setCardPaymentErrMsg?: (err: string) => void;
}) => {
    const [savedCards, setSavedCards] = useState<SavedCard[]>([]);
    const { values, isSubmitting, setFieldValue } = useFormikContext<FormValues>();
    const dispatch = useDispatch();
    const cardDropdownOptions: SelectOption[] = savedCards.map((card) => ({
        value: card.id,
        label: `${card.cardDescription} - ${card.cardholderName}`,
    }));

    useEffect(() => {
        instance
            .get<ApiResponse<SavedCard[]>>(props.cardListUrl ?? endpoints.accounts.getPaymentCards)
            .then((res) => res.data.details && setSavedCards(res.data.details))
            .catch((err) =>
                Toast.openToastMessage(
                    "We couldn't load your saved cards",
                    ToastMessageReason.ERROR
                )
            );
    }, [dispatch, props.cardListUrl]);
    const form = (
        <div className="PayInForm">
            {values.cardType === 'Saved card' && (
                <div className="ExistingCardOptions">
                    <WrappedFormSingleSelectField
                        fieldName="paymentCardsId"
                        options={cardDropdownOptions}
                        label="Choose saved card"
                        dropdownProps={{
                            /* 
                className: 'CardSelect',
                classNamePrefix: 'CardSelect', */
                            placeholder: 'Please select',
                        }}
                    />
                    <FormTextField field="cvV2" label="CVC" required className="CvvInput" />
                </div>
            )}
            {!props.plainForm && (
                <FormTextField
                    field="amount"
                    label="Amount to deposit"
                    required
                    inputMode="decimal"
                    type="number"
                />
            )}
            {props.errorMessage && <div className="ErrorLabel">{props.errorMessage}</div>}
            {!props.plainForm && (
                <Button disabled={isSubmitting} variety="full">
                    {values.cardType === 'New card' ? 'Enter Card Details' : 'Deposit'}
                </Button>
            )}
            {props.plainForm && props.accordion && (
                <Button type="submit" variety="full" disabled={isSubmitting}>
                    Make payment
                </Button>
            )}
        </div>
    );
    if (cardDropdownOptions.length === 0 && props.accordion) return null;
    return props.accordion ? (
        <ManualCollapse
            header={'Pay with saved card'}
            isOpen={values.cardType === 'Saved card'}
            setOpen={(open: boolean) => {
                setFieldValue('cardType', open ? 'Saved card' : null);
                props.setCardPaymentErrMsg && props.setCardPaymentErrMsg('');
            }}
        >
            {form}
        </ManualCollapse>
    ) : (
        <>{form}</>
    );
};

const TransferDetails = (props: { accountNumber: string; accountType: AccountType }) => {
    const theme = useTheme();
    const boxCSS = css`
        border: 1px solid ${theme.colors.first};
        color: ${theme.colors.first};
    `;
    const accountDetails = useGetFiatAccountDetails();

    const isCRB = accountDetails?.productDisplayName === ProductType.CRB;
    const isDBS =
        accountDetails?.productDisplayName === ProductType.DBS ||
        accountDetails?.productDisplayName === ProductType.DBS_TMP;
    const isGLDB = accountDetails?.productDisplayName === ProductType.GLDB;
    return (
        <table className="TransferDetails" css={boxCSS}>
            <tbody>
                <tr className="SectionHeading">
                    <th colSpan={2}>Account Details:</th>
                </tr>
                {accountDetails?.swiftBic && (
                    <tr>
                        <td className="Label">Swift / BIC:</td>
                        <td className="Value">{accountDetails.swiftBic}</td>
                    </tr>
                )}
                {!(accountDetails?.swiftBic && !accountDetails.routingNumber) && ( //if we have BIC but no routing number hide the routing number
                    <tr>
                        <td className="Label">Routing Number:</td>
                        <td className="Value">{accountDetails?.routingNumber ?? 'XXXXXXXXX'}</td>
                    </tr>
                )}
                {!!accountDetails?.virtualAccountNumber ? (
                    <tr>
                        <td className="Label">IBAN:</td>
                        <td className="Value">{accountDetails.virtualAccountNumber}</td>
                    </tr>
                ) : (
                    <tr>
                        <td className="Label">Account Number:</td>
                        <td className="Value">{accountDetails?.accountNumber ?? 'XXXXXXXXXX'}</td>
                    </tr>
                )}
                {accountDetails?.reference && (
                    <tr>
                        <td className="Label">Reference:</td>
                        <td className="Value">{accountDetails.reference}</td>
                    </tr>
                )}
            </tbody>

            <tbody>
                <tr className="SectionHeading">
                    <th colSpan={2}>Receiving bank:</th>
                </tr>
                <tr>
                    <td className="Label">Name:</td>
                    <td className="Value">{accountDetails?.productDisplayName}</td>
                </tr>
                <tr>
                    <td className="Label">Address:</td>
                    <td className="Value">{accountDetails?.productAddress}</td>
                </tr>
            </tbody>

            <tbody>
                <tr className="SectionHeading">
                    <th colSpan={2}>Remittance Information:</th>
                </tr>
                {accountDetails?.vaName && (
                    <>
                        <tr>
                            <td className="Label">Name:</td>
                            <td className="Value">{accountDetails.vaName}</td>
                        </tr>
                        <tr>
                            <td className="Label">Payment Details Field (F70):</td>
                            <td className="Value">
                                {isCRB
                                    ? 'Ibanera LLC'
                                    : isDBS
                                    ? 'Ibanera Pte Ltd'
                                    : isGLDB
                                    ? 'Ibanera Pte Ltd'
                                    : ''}
                            </td>
                        </tr>
                        {accountDetails.vaAddress && (
                            <tr>
                                <td className="Label">Address:</td>
                                <td className="Value">{accountDetails.vaAddress}</td>
                            </tr>
                        )}
                    </>
                )}
                {!accountDetails?.vaName && (
                    <>
                        <tr>
                            <td className="Label">Name:</td>
                            <td className="Value">{isCRB ? 'Ibanera LLC' : 'Ibanera Pte Ltd'}</td>
                        </tr>
                        <tr>
                            <td className="Label">Address:</td>
                            <td className="Value">
                                {isCRB
                                    ? '78 SW 7th St 7-118 Miami FL 33130'
                                    : isDBS
                                    ? '62 Ubi Road 1, #03-16 Oxley Bizhub 2, Singapore 408734'
                                    : isGLDB
                                    ? '62 Ubi Road 1, #03-16 Oxley Bizhub 2, Singapore 408734'
                                    : ''}
                            </td>
                        </tr>
                    </>
                )}
            </tbody>
        </table>
    );
};
