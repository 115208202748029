import FormTextField from 'components/form/FormTextField';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';

import { ReactComponent as DefaultCrypto } from 'assets/currencyIcons/default.svg';

import { FormValues } from './QuickCoinPage';
import { useSelector } from 'react-redux';
import { selectCurrencySymbols } from 'reducers/currencySymbols';
import { debounce } from 'lodash';
import instance, { ApiResponse, isAxiosErrorHandled } from 'api';
import { endpoints } from 'endpoints.config';
import { AxiosResponse } from 'axios';
import { useTheme } from '@emotion/react';
import { TransferType } from 'pages/crypto/Crypto';

export const FiatToggle = ({
    currencyCode,
    assetCode,
}: {
    currencyCode: string;
    assetCode: string;
}) => {
    const currencySymbols = useSelector(selectCurrencySymbols);
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const isFiat = values.quantityType === 'Price';
    const theme = useTheme();
    return (
        <div className="Toggle">
            <label className="switch">
                <input
                    type="checkbox"
                    onChange={(e) =>
                        setFieldValue('quantityType', e.target.checked ? 'Price' : 'Amount')
                    }
                />
                <span className="slider round manual">
                    <span
                        className="SlidingIcon"
                        style={{
                            backgroundColor: isFiat ? theme.colors.second : theme.colors.first,
                            borderColor: isFiat ? theme.colors.second : theme.colors.first,
                        }}
                    >
                        {isFiat
                            ? currencySymbols[currencyCode] || '$'
                            : currencySymbols[assetCode] || (
                                  <DefaultCrypto fill={theme.colors.first} />
                              )}
                    </span>
                </span>
            </label>
        </div>
    );
};

type GetPriceParams = {
    asset: string;
    pairedAsset: string;
    assetExchangeType: 'Buy' | 'Sell';
    amount: number;
    transferType?: TransferType;
    bVirtualBuy?: boolean;
    feeId?: number;
};
type GetAmountParams = Omit<GetPriceParams, 'amount'> & { price: number };
export const CalculatedPriceField = ({
    label,
    isFiat,
    hint,
    assetExchangeType = 'Buy',
    enabled = true,
    getPriceUrl,
    getAmountUrl,
    priceFieldKey = 'price',
}: {
    label: React.ReactNode;
    isFiat: boolean;
    hint?: string;
    assetExchangeType?: 'Buy' | 'Sell';
    enabled?: boolean;
    getPriceUrl?: string;
    getAmountUrl?: string;
    // Not ideal but needed while using both v1 and v2
    priceFieldKey?: string;
}) => {
    const { values, setFieldValue, setFieldError, setTouched } = useFormikContext<
        FormValues & { transferType?: TransferType; bVirtualBuy?: boolean; feeId?: number }
    >();
    const {
        asset,
        amount,
        price,
        currency: pairedAsset,
        transferType,
        bVirtualBuy,
        feeId,
    } = values;
    const [calculating, setCalculating] = useState(false);
    const debouncedCalculatePrice = useCallback(
        debounce(
            async (
                {
                    asset,
                    pairedAsset,
                    assetExchangeType,
                    transferType,
                    amount,
                    bVirtualBuy,
                    feeId,
                }: GetPriceParams,
                successHandler: (
                    result: AxiosResponse<ApiResponse<{ [priceField: string]: number }>>
                ) => void,
                errorHandler: (err: any) => void
            ) => {
                try {
                    const result = await instance.get<ApiResponse<{ priceFieldKey: number }>>(
                        getPriceUrl ?? endpoints.pricesModule.getPrice,
                        {
                            params: {
                                asset,
                                pairedAsset,
                                assetExchangeType,
                                amount,
                                transferType:
                                    assetExchangeType === 'Sell' ? transferType : undefined,
                                bVirtualBuy: assetExchangeType === 'Buy' ? bVirtualBuy : undefined,
                                bankFeeSplitType: feeId,
                            },
                        }
                    );
                    successHandler(result);
                } catch (err: any) {
                    errorHandler(err);
                }
                setCalculating(false);
            },
            500
        ),
        [getPriceUrl]
    );
    const debouncedCalculateAmount = useCallback(
        debounce(
            async (
                {
                    asset,
                    pairedAsset,
                    assetExchangeType,
                    transferType,
                    price,
                    bVirtualBuy,
                    feeId,
                }: GetAmountParams,
                successHandler: (result: AxiosResponse<ApiResponse<{ amount: number }>>) => void,
                errorHandler: (err: any) => void
            ) => {
                try {
                    const result = await instance.get<ApiResponse<{ amount: number }>>(
                        getAmountUrl ?? endpoints.pricesModule.getAmount,
                        {
                            params: {
                                asset,
                                pairedAsset,
                                assetExchangeType,
                                price,
                                transferType:
                                    assetExchangeType === 'Sell' ? transferType : undefined,
                                bVirtualBuy: assetExchangeType === 'Buy' ? bVirtualBuy : undefined,
                                bankFeeSplitType: feeId,
                            },
                        }
                    );
                    successHandler(result);
                } catch (err: any) {
                    errorHandler(err);
                }
                setCalculating(false);
            },

            500
        ),
        [getAmountUrl]
    );

    useEffect(() => {
        if (isFiat && price) {
            setCalculating(true);
            debouncedCalculateAmount(
                { asset, pairedAsset, assetExchangeType, price, transferType, bVirtualBuy, feeId },
                (res) => setFieldValue('amount', res.data.details.amount),
                (err) => {
                    setTouched({
                        amount: true,
                        price: true,
                    });
                    setFieldValue('amount', 0, false);
                    if (
                        isAxiosErrorHandled(err) &&
                        err.response.data.errors.some(
                            (error) => error.messageCode === 'Price_Too_Low'
                        )
                    ) {
                        setFieldError('price', 'Price too low');
                    } else if (
                        isAxiosErrorHandled(err) &&
                        err.response.data.errors.some(
                            (error) => error.messageCode === 'Price_Too_High'
                        )
                    ) {
                        setFieldError('price', 'Price too high');
                    } else setFieldError('amount', "Couldn't calculate amount");
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isFiat,
        price,
        feeId,
        asset,
        pairedAsset,
        transferType,
        bVirtualBuy,
        assetExchangeType,
        debouncedCalculateAmount,
        setFieldValue,
        setFieldError,
    ]);
    useEffect(() => {
        if (!isFiat && amount) {
            setCalculating(true);
            debouncedCalculatePrice(
                { asset, pairedAsset, assetExchangeType, amount, transferType, bVirtualBuy, feeId },
                (res) => setFieldValue('price', res.data.details[priceFieldKey]),
                (err) => {
                    setTouched({
                        price: true,
                        amount: true,
                    });
                    setFieldValue('price', 0);

                    if (
                        isAxiosErrorHandled(err) &&
                        err.response.data.errors.some(
                            (error) => error.messageCode === 'Price_Too_High'
                        )
                    ) {
                        setFieldError('amount', 'Price too high');
                    } else setFieldError('price', "Couldn't calculate price");
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isFiat,
        amount,
        feeId,
        asset,
        pairedAsset,
        transferType,
        bVirtualBuy,
        assetExchangeType,
        debouncedCalculatePrice,
        setFieldValue,
        setFieldError,
    ]);

    return calculating || !enabled ? (
        <div className="FormBox">
            <div className="FormLabel">
                <label>{label}</label>
            </div>
            <div className="FormField">
                <div className="EditBox PriceField ViewOnly">
                    {enabled ? 'Calculating...' : ' '}
                </div>
            </div>
        </div>
    ) : (
        <FormTextField
            required={false}
            field={isFiat ? 'amount' : 'price'}
            label={label}
            readOnly
            key={isFiat ? 'amount' : 'price'}
            hint={hint}
            className="PriceField"
            autoComplete={false}
        />
    );
};
